import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p><img src="/drink-chicken-1.png" className="App-logo" alt="logo" /></p>
        <h1>DrinkChicken.com</h1>


      </header>
    </div>
  );
}

export default App;
